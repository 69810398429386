<template>
  <div class="container">
    <el-button type="text"
               @click="showTable">查看列表</el-button>
    <el-dialog title="关联商户列表"
               :close-on-click-modal='false'
               :visible.sync="dialogVisible"
               width="50%">
      <table-page dataName="merchants"
                  :search="search"
                  ref="table"
                  init
                  :params="paramsObj"
                  :request="searchWithVoucherId">
        <el-table-column prop="name"
                         show-overflow-tooltip
                         label="商户名称">
        </el-table-column>
        <el-table-column prop="merchant_num"
                         show-overflow-tooltip
                         label="商户号">
        </el-table-column>
      </table-page>
      <span slot="footer"
            class="dialog-footer">

      </span>
    </el-dialog>
  </div>
</template>

<script>
import TablePage from '@/components/TablePage'

import { searchWithVoucherId } from '@/api/market'
export default {
  props: ['params'],
  data () {
    return {
      searchWithVoucherId,
      dialogVisible: false,
      paramsObj: {
      },
      search: [{
        type: 'input',
        placeholder: '请输入商户号',
        key: 'keyword',
        value: ''
      }]
    }
  },
  components: {
    TablePage
  },

  methods: {
    showTable () {
      this.paramsObj = {
        coupon_id: this.params.id
      }
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.table.fetch()
        this.$refs.search.reset()
      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="less" scoped>
</style>
