<template>
  <div class="">
    <el-dialog :visible.sync="dialogVisible"
               :close-on-click-modal='false'
               width="50%">
      <el-form ref="form"
               label-position="top"
               :model="form">
        <el-form-item label="品牌名称">
          <el-input v-model="form.brand_title"></el-input>
        </el-form-item>
        <el-form-item label="品牌图片">
          <ImgUpload :fileList.sync="imgList"
                     v-model="form.logo_id" />
        </el-form-item>
        <el-form-item label="副标题">
          <el-input v-model="form.subtitle"></el-input>
        </el-form-item>
        <el-form-item label="是否显示可用商户按钮">
          <el-switch :active-value="1"
                     :inactive-value="0"
                     v-model="form.is_usable_merchants"></el-switch>
        </el-form-item>
        <el-form-item label="「立即使用」按钮交互方式">
          <el-radio-group v-model="form.interaction_type">
            <el-radio :label="1">收款码/扫一扫</el-radio>
            <el-radio :label="2">跳转小程序</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="AppID"
                      v-if="form.interaction_type === 2">
          <el-input v-model="form.jump_appid"
                    placeholder="请输入appid"></el-input>
        </el-form-item>
        <el-form-item label="路径"
                      v-if="form.interaction_type === 2">
          <el-input v-model="form.jump_url"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="submitForm('form')">立即修改</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ImgUpload from '@/components/ImgUpload'
import { setVoucher } from '@/api/market'
export default {
  name: '',
  data () {
    return {
      imgList: [],
      dialogVisible: false,
      form: {
        brand_title: '',
        id: '',
        logo_id: '',
        subtitle: '在指定微邮付商户处可用',
        is_usable_merchants: 0,
        interaction_type: 1,
        jump_appid: '',
        jump_url: ''
      }
    }
  },
  components: {
    ImgUpload
  },
  methods: {
    showModal (form) {
      this.dialogVisible = true
      form.logo_id = form.logo.id
      this.form = form

      this.$nextTick(() => {
        this.$set(this.imgList, 0, form.logo)
        this.$refs.form.clearValidate()
      })
    },
    submitForm (formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const res = await setVoucher(this.form)
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: '修改成功',
              type: 'success'
            })
            this.dialogVisible = false
            this.$emit('on-success')
          } else {
            this.$alert(res.meta.msg, {
              type: 'error',
              content: res.meta.msg
            })
          }
        } else {
          this.$message.error('表单信息填写有误, 请检查')
        }
      })
    }
  },
  mounted () { }
}
</script>

<style lang="scss" scoped>
</style>
