<template>
  <div class="container">
    <table-page dataName="coupons"
                ref="table"
                :search="search"
                :request="fetchVouchers">
      <template slot="button">
        <el-button type="primary"
                   @click="$refs.createVoucher.showModal()">新建代金券</el-button>
      </template>
      <el-table-column prop="id"
                       show-overflow-tooltip
                       label="ID">
      </el-table-column>
      <el-table-column prop="coupon_stock_id"
                       show-overflow-tooltip
                       label="批次号">
      </el-table-column>
      <el-table-column prop="title"
                       show-overflow-tooltip
                       label="券名称">
      </el-table-column>
      <el-table-column prop="inventory"
                       show-overflow-tooltip
                       label="当前库存">
      </el-table-column>
      <el-table-column prop="total_inventory"
                       show-overflow-tooltip
                       label="总数量">
      </el-table-column>
      <el-table-column label="有效期"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-if="scope.row.date_info.type === 'DATE_TYPE_FIX_TIME_RANGE'">
            {{scope.row.date_info.begined_at}}到{{scope.row.date_info.ended_at}}
          </span>
          <span v-if="scope.row.date_info.type === 'DATE_TYPE_FIX_TERM'">
            领取后{{scope.row.date_info.fixed_term}}天有效，有效天数{{scope.row.date_info.fixed_begin_term}}天
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="created_at"
                       show-overflow-tooltip
                       label="创建时间">
      </el-table-column>
      <el-table-column label="关联商户">
        <template slot-scope="scope">
          <Merchants :params="scope.row" />
        </template>

      </el-table-column>
      <el-table-column label="操作"
                       width="250px">
        <template slot-scope="scope">
          <el-button type="text"
                     @click="$refs.setVoucher.showModal(scope.row)">编辑</el-button>
          <el-button type="text"
                     @click="$refs.relevance.showModel(scope.row.id)">关联商户</el-button>
          <el-button type="text"
                     @click="refresh(scope.row)">刷新库存</el-button>
        </template>
      </el-table-column>
    </table-page>
    <Relevance ref="relevance"
               @on-success="$refs.table.fetch()" />
    <SetVoucher ref="setVoucher"
                @on-success="$refs.table.fetch()" />
    <CreateVoucher ref="createVoucher"
                   @on-success="$refs.table.fetch()" />
  </div>
</template>

<script>
import TablePage from '@/components/TablePage'
import CreateVoucher from './components/CreateVoucher'
import Merchants from './components/Merchants'
import Relevance from './components/Relevance'
import SetVoucher from './components/SetVoucher'
import { fetchVouchers, delActivity, creatVoucher } from '@/api/market'
export default {
  name: 'Vouchers',
  data () {
    return {
      search: [{
        type: 'input',
        name: '批次号',
        placeholder: '请输入代金券批次号',
        key: 'coupon_stock_id',
        value: ''
      }, {
        type: 'input',
        name: '名称',
        placeholder: '请输入代金券名称',
        key: 'name',
        value: ''
      }],
      fetchVouchers
    }
  },
  components: {
    TablePage,
    Merchants,
    Relevance,
    SetVoucher,
    CreateVoucher
  },
  methods: {
    setTerrace (row) {
      this.$refs.form.showModel(row)
    },
    delActivity (id) {
      this.$confirm('确认删除', {
        type: 'warning'
      }).then(async () => {
        const res = await delActivity({
          activity_ids: [id]
        })
        if (res.meta.code === 0) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          })
          this.$refs.table.fetch()
        }
      })
    },
    async refresh (row) {
      const res = await creatVoucher({
        coupon_stock_ids: [row.coupon_stock_id],
        partner_id: row.partner_id
      })
      if (res.meta.code === 0) {
        if (res.data.coupon_res[0].msg === 'SUCCESS') {
          this.$message.success('刷新成功')
          this.$refs.table.fetch()
        } else {
          this.$message.error(`批次${res.data.coupon_res[0].coupon_stock_id}错误，错误信息${res.data.coupon_res[0].msg}`)
        }
      } else {
        this.$message.error(`批次${res.data.coupon_res[0].coupon_stock_id}错误，错误信息${res.data.coupon_res[0].msg}`)
      }
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
</style>
