<template>
  <el-dialog title="新建代金券"
             :visible.sync="dialogVisible"
             :close-on-click-modal='false'
             width="40%">
    <el-form ref="form"
             :model="form"
             :rules="rules"
             label-position="top">
      <el-form-item label="请选择制券方"
                    prop="partner_id">
        <el-select v-model="form.partner_id"
                   class="width100"
                   placeholder="请选择">
          <el-option v-for="(item,index) in partherList"
                     :label="`${item.mch_id}(${item.name})`"
                     :key="index"
                     :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="代金券批次号"
                    prop="coupon_stock_ids">
        <el-input v-model="form.coupon_stock_ids"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer"
          class="dialog-footer">
      <el-button type="primary"
                 @click="onSubmit('form')">立即创建</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { creatVoucher } from '@/api/market'
import { fetchPartners } from '@/api/terrace'
export default {
  data () {
    return {
      dialogVisible: false,
      form: {
        partner_id: '',
        coupon_stock_ids: ''
      },
      rules: {
        partner_id: [{
          required: true, message: '请输入选择制券方', trigger: 'change'
        }],
        coupon_stock_ids: [{
          required: true, message: '请输入批次号', trigger: 'blur'
        }]
      },
      partherList: []
    }
  },
  components: {
  },
  methods: {
    showModal () {
      this.dialogVisible = true
    },
    async fetchPartners () {
      const res = await fetchPartners({
        page: 1,
        size: 100
      })
      if (res.meta.code === 0) {
        this.partherList = res.data.partners
      }
    },
    onSubmit (name) {
      this.$refs[name].validate(async (valid) => {
        if (valid) {
          const form = Object.assign({}, this.form)
          form.coupon_stock_ids = [form.coupon_stock_ids]
          const res = await creatVoucher(form)
          if (res.meta.code === 0) {
            if (res.data.coupon_res[0].msg === 'SUCCESS') {
              this.$notify({
                title: '成功',
                message: '创建成功',
                type: 'success'
              })
              this.dialogVisible = false
              this.$emit('on-success')
            } else {
              this.$message.error(`批次${res.data.coupon_res[0].coupon_stock_id}错误，错误信息${res.data.coupon_res[0].msg}`)
            }
          } else {
            this.$alert(res.meta.msg, {
              type: 'error',
              content: res.meta.msg
            })
          }
        }
      })
    }
  },
  mounted () {
    this.fetchPartners()
  }
}
</script>

<style lang="less" scoped>
</style>
